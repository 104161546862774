<div class="i_left">
  <!-- 歷史取近 4 期 -->
  <div id="his_box_1">
    <!-- 5分 -->
    <div class="kj_history">
      <h3>số trúng thưởng<a href="" [routerLink]="'/history/' + 'hn5fc'" class="kj_history-more_btn">hơn &gt;</a></h3>
      <div class="kj_history_list">
        <!-- 5分 -->
        <ul class="clear" *ngIf="hn5fcDrawHistoryByPage">
          <li *ngFor="let historyItem of hn5fcDrawHistoryByPage.data">
            <div class="history_ball">
              <em *ngFor="let openNum of historyItem?.drawResult?.split(',')">{{openNum}}</em>
            </div>
            <span>{{historyItem.issue?.substring(0,8)+ '-' + historyItem.issue?.substring(8)}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- 1分 -->
  <!-- <div id="his_box_2">
    <div class="kj_history">
      <h3>số trúng thưởng<a href="" [routerLink]="'/history/' + 'hn1fc'" class="kj_history-more_btn">hơn &gt;</a></h3>
      <div class="kj_history_list">
        <ul class="clear" *ngIf="hn1fcDrawHistoryByPage">
          <li *ngFor="let historyItem of hn1fcDrawHistoryByPage.data">
            <div class="history_ball">
              <em *ngFor="let openNum of historyItem?.drawResult?.split(',')">{{openNum}}</em>
            </div>
            <span>{{historyItem.issue?.substring(0,8)+ '-' + historyItem.issue?.substring(8)}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div> -->
</div>
<div class="i_right">
  <a class="i_website fl" href="" routerLink="/home" target="_blank"></a>
  <div id="box1">
    <!-- 5分 -->
    <div class="kj_box">
      <h2 style="display: block;">Quick 5
        <ng-container *ngIf="!hn5fcDrawHistoryByPage">00000000-000</ng-container>
        <ng-container *ngIf="hn5fcDrawHistoryByPage">
          {{hn5fcDrawHistoryByPage.data[0]?.issue?.substring(0,8) + '-' + hn5fcDrawHistoryByPage.data[0]?.issue?.substring(8)}}
        </ng-container>
      </h2>
      <div class="kj_ball">
        <ul>
          <ng-container *ngIf="!hn5fcDrawHistoryByPage">
            <li *ngFor="let openNum of [1,1,1,1,1]">
              ◆
            </li>
          </ng-container>
          <ng-container *ngIf="hn5fcDrawHistoryByPage">
            <li *ngFor="let openNum of hn5fcDrawHistoryByPage.data[0].drawResult?.split(',')">
              {{openNum}}
            </li>
          </ng-container>
        </ul>
      </div>
      <div id="kj_time_1" class="kj_time">xổ số đếm ngược：
        <ng-container *ngIf="!hn5fcLotteryGameInfo">00:00:00</ng-container>
        <app-timer-countdown *ngIf="hn5fcLotteryGameInfo" (timesUp)="timesUp({code:'hn5fc'})"
          [endTime]="transformDate(hn5fcLotteryGameInfo.currentCycle?.endTime)">
        </app-timer-countdown>
      </div>
    </div>
  </div>
  <!-- 1分 -->
  <!-- <div id="box2">
    <div class="kj_box">
      <h2 style="display: block;">Quick 1
        <ng-container *ngIf="!hn1fcDrawHistoryByPage">00000000-000</ng-container>
        <ng-container *ngIf="hn1fcDrawHistoryByPage">
          {{hn1fcDrawHistoryByPage.data[0]?.issue?.substring(0,8) + '-' + hn1fcDrawHistoryByPage.data[0]?.issue?.substring(8)}}
        </ng-container>
      </h2>
      <div class="kj_ball">
        <ul>
          <ng-container *ngIf="!hn1fcDrawHistoryByPage">
            <li *ngFor="let openNum of [1,1,1,1,1]">
              ◆
            </li>
          </ng-container>
          <ng-container *ngIf="hn1fcDrawHistoryByPage">
            <li *ngFor="let openNum of hn1fcDrawHistoryByPage.data[0].drawResult.split(',')">
              {{openNum}}
            </li>
          </ng-container>
        </ul>
      </div>
      <div id="kj_time_1" class="kj_time">xổ số đếm ngược：
        <ng-container *ngIf="!hn1fcLotteryGameInfo">00:00:00</ng-container>
        <app-timer-countdown *ngIf="hn1fcLotteryGameInfo" (timesUp)="timesUp({code:'hn1fc'})"
          [endTime]="transformDate(hn1fcLotteryGameInfo.currentCycle?.endTime)">
        </app-timer-countdown>
      </div>
    </div>
  </div> -->
  <div style="clear:both"></div>
  <div class="link">
    <h3>tổ chức</h3>
    <div class="link_list">
      <ul>
        <li><img src="assets/images/i_link-img1.jpg"></li>
        <li><img src="assets/images/i_link-img2.jpg"></li>
        <li><img src="assets/images/i_link-img3.jpg"></li>
        <li><img src="assets/images/i_link-img4.jpg"></li>
        <li><img src="assets/images/i_link-img5.jpg"></li>
        <li><img src="assets/images/i_link-img6.jpg"></li>
        <li><img src="assets/images/i_link-img7.jpg"></li>
        <li><img src="assets/images/i_link-img8.jpg"></li>
        <li><img src="assets/images/i_link-img9.jpg"></li>
        <li><img src="assets/images/i_link-img10.jpg"></li>
      </ul>
    </div>
  </div>
</div>