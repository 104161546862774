<div class="header">
  <div class="hd_top w980">
    <div class="national-flag">
      <img src="assets/images/header_national-flag1.png">
      <a href="#">
        <img src="assets/images/header_national-flag2.png"></a>
    </div>
    <h1 class="logo"></h1>
  </div>
  <div class="nav">
    <ul>
      <li><a href="" routerLink="/home" class="home">quê hương</a></li>
      <li><a href="" routerLink="/history/hn5fc">phân tích xổ số Quick5</a></li>
      <!-- <li><a href="" routerLink="/history/hn1fc">phân tích xổ số Quick1</a></li> -->
      <li><a href="" routerLink="/home" target="_blank">trang web chính thức</a></li>
    </ul>
  </div>
  <div class="w980">
    <div class="banner"><span class="jiao"></span></div>
  </div>
</div>

<div class="i_content w980">
  <router-outlet></router-outlet>
</div>

<div class="footer w980">
  <p class="f_nav"><a href="http://vietlotto.org/vietlott.vn/vn/Van-ban-phap-quy.html" target="_blank">Chính sách pháp
      lý</a>|<a href="http://vietlotto.org/vietlott.vn/vn/Lien-he.html" target="_blank">Liên hệ</a></p>
  <p>Bản quyền thuộc về Công ty Xổ Số Điện Toán Việt Nam <br>
    Địa chỉ: Tầng 15, Tòa nhà CornerStone, 16 Phan Chu Trinh, Quận Hoàn Kiếm, Hà Nội<br>
    Tel: <span class="red-text">04.62.686.818</span> - Fax: <span class="red-text">04.62.686.800</span>　Email:<span
      class="red-text">contact@vietlott.vn/banbientap@vietlott.vn</span></p>
  <p class="copyright">© 2017 Vietlott</p>
</div>