import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { animationFrameScheduler, of, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, repeat } from 'rxjs/operators';

@Component({
  selector: 'app-timer-countdown',
  templateUrl: './timer-countdown.component.html',
  styleUrls: ['./timer-countdown.component.scss']
})
export class TimerCountdownComponent implements OnInit {
  _endTime: Date;
  @Input() set endTime(date: Date) {
    this.isEnd = false;
    this._endTime = date;
  }
  get endTime(): Date {
    return this._endTime;
  }
  @Output() timesUp = new EventEmitter();
  isEnd = false;
  time$: Observable<string>;

  constructor() {
    this.time$ = of(0, animationFrameScheduler).pipe(
      repeat(),
      filter(() => !this.isEnd),
      map(() => {
        const now = new Date();
        if (this.endTime == null) {
          return '00:00:00';
        }
        const diff = Math.floor((this.endTime.getTime() - now.getTime()) / 1000);
        if (diff < 0) {
          this.isEnd = true;
          this.timesUp.emit();
          return '00:00:00';
        }
        if (isNaN(diff)) {
          console.log('is nan:', diff);
        }
        return this.secToHHMMSS(diff);
      }),
      distinctUntilChanged(),
    );
  }

  ngOnInit() { }

  secToHHMMSS(secs: number) {
    if (secs <= 0) { return '00:00:00'; }
    const hours = Math.floor(secs / 3600);
    const minutes = Math.floor(secs / 60) % 60;
    const seconds = secs % 60;
    return [hours, minutes, seconds]
      .map(v => v < 10 ? '0' + v : v)
      .join(':');
  }
}
