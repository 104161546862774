import { DrawHistoryByPageInput, LotteryService } from 'src/app/lottery.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ApolloQueryResult } from '@apollo/client/core';
import { DrawResultPaginator } from 'src/app/core/schema';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit, OnDestroy {
  code = '';
  queryInput: DrawHistoryByPageInput;
  loading = false;
  drawHistoryByPage: DrawResultPaginator | null;
  sub: Subscription[] = [];
  perCycleTime = 0;

  constructor(
    private lotterySer: LotteryService,
    private router: Router,
  ) {
    this.getCode();
  }

  ngOnInit() {
    const sub = this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(() => {
        this.drawHistoryByPage = null;
        this.getCode();
      });
    this.sub.push(sub);
  }

  ngOnDestroy() {
    this.sub.forEach(s => s.unsubscribe());
  }

  getCode() {
    this.code = this.router.url.replace('/history/', '');
    this.perCycleTime = Number(this.code.replace('hn', '').replace('fc', ''));
    this.queryInput = {
      code: this.code,
      // 5分彩一小時最多開"12"期，1分彩一小時最多開"60"期
      // 後端還沒給時間篩選 先寫死
      rowsPerPage: this.code === 'hn1fc' ? 60 : 12,
      page: 1,
    };
    this.getDrawHistoryByPage();
  }

  getDrawHistoryByPage() {
    const sub = this.lotterySer.getDrawHistoryByPage(this.queryInput).valueChanges.subscribe((result: ApolloQueryResult<any>) => {
      this.loading = result.loading;
      if (result?.data && !result?.loading) {
        this.drawHistoryByPage = result.data.drawHistoryByPage;
      }
    });
    this.sub.push(sub);
  }

  countStartTime(cycleVal: string, perCycleTime: number) { // 利用 期號 計算表定開獎時間
    const c = Number(cycleVal.substring(8)); // YYYYMMDD = 8
    const h = Math.floor((c * perCycleTime) / 60);
    const m = (c * perCycleTime) % 60;
    return ((h.toString().length > 1 ? '' : '0') + h.toString()) + ':' + ((m.toString().length > 1 ? '' : '0') + m.toString());
  }

}
