<div class="i_left">
  <a class="i_website" style="margin-bottom:10px;" href="" target="_blank" routerLink="/home"></a>
  <div class="kj_history">
    <h3>tổ chức</h3>
    <div class="link_list">
      <ul class="clear">
        <li><img src="assets/images/i_link-img1.jpg"></li>
        <li><img src="assets/images/i_link-img2.jpg"></li>
        <li><img src="assets/images/i_link-img3.jpg"></li>
        <li><img src="assets/images/i_link-img4.jpg"></li>
        <li><img src="assets/images/i_link-img5.jpg"></li>
        <li><img src="assets/images/i_link-img6.jpg"></li>
        <li><img src="assets/images/i_link-img7.jpg"></li>
        <li><img src="assets/images/i_link-img8.jpg"></li>
        <li><img src="assets/images/i_link-img9.jpg"></li>
        <li><img src="assets/images/i_link-img10.jpg"></li>
        <li><img src="assets/images/i_link-img11.jpg"></li>
        <li><img src="assets/images/i_link-img12.jpg"></li>
      </ul>
    </div>
  </div>
</div>
<div class="list_right">
  <div class="list_right_top">
    <h2>LƯU TRỮ SỐ rút ra</h2>
    <div class="time">GIỜ MỞ：<span>Thứ Hai-Chủ Nhật 00:00-24:00</span></div>
    <!-- todo:後端還沒u做時間篩選內 -->
    <!-- <form action="" method="GET">
      <div class="search_box">
        Ngày hòa:
        <select name="day" id="day">
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
          <option value="11">11</option>
          <option value="12">12</option>
          <option value="13">13</option>
          <option value="14">14</option>
          <option value="15">15</option>
          <option value="16">16</option>
          <option value="17">17</option>
          <option value="18">18</option>
          <option value="19">19</option>
          <option value="20">20</option>
          <option value="21">21</option>
          <option value="22">22</option>
          <option value="23">23</option>
          <option value="24">24</option>
          <option value="25">25</option>
          <option value="26" selected="">26</option>
          <option value="27">27</option>
          <option value="28">28</option>
          <option value="29">29</option>
          <option value="30">30</option>
          <option value="31">31</option>
        </select><select name="month" id="month">
          <option value="1" selected="">Jan</option>
          <option value="2">Feb</option>
          <option value="3">Mar</option>
          <option value="4">Apr</option>
          <option value="5">May</option>
          <option value="6">Jun</option>
          <option value="7">Jul</option>
          <option value="8">Aug</option>
          <option value="9">Sep</option>
          <option value="10">Oct</option>
          <option value="11">Nov</option>
          <option value="12">Dec</option>
        </select><select name="year" id="year">
          <option value="2013">2013</option>
          <option value="2014">2014</option>
          <option value="2015">2015</option>
          <option value="2016">2016</option>
          <option value="2017">2017</option>
          <option value="2018">2018</option>
          <option value="2019">2019</option>
          <option value="2020">2020</option>
          <option value="2021" selected="">2021</option>
        </select>
        thời gian:
        <select name="hour" id="hour">
          <option value="0">00:00--00:59</option>
          <option value="1">01:00--01:59</option>
          <option value="2">02:00--02:59</option>
          <option value="3">03:00--03:59</option>
          <option value="4">04:00--04:59</option>
          <option value="5">05:00--05:59</option>
          <option value="6">06:00--06:59</option>
          <option value="7">07:00--07:59</option>
          <option value="8">08:00--08:59</option>
          <option value="9">09:00--09:59</option>
          <option value="10">10:00--10:59</option>
          <option value="11">11:00--11:59</option>
          <option value="12" selected="">12:00--12:59</option>
          <option value="13">13:00--13:59</option>
          <option value="14">14:00--14:59</option>
          <option value="15">15:00--15:59</option>
          <option value="16">16:00--16:59</option>
          <option value="17">17:00--17:59</option>
          <option value="18">18:00--18:59</option>
          <option value="19">19:00--19:59</option>
          <option value="20">20:00--20:59</option>
          <option value="21">21:00--21:59</option>
          <option value="22">22:00--22:59</option>
          <option value="23">23:00--23:59</option>
        </select>　
        <input type="submit" class="display_btn" value="biểu dương">
      </div>
    </form> -->
  </div>
  <div class="list_right_box" *ngIf="drawHistoryByPage">
    <div class="item" *ngFor="let historyItem of drawHistoryByPage.data">
      <div class="time">rút ra thời gian
        {{countStartTime(historyItem.issue,perCycleTime)}}:00</div>
      <!-- <div class="time">rút ra thời gian {{historyItem.drawTime}}</div> -->
      <div class="b_box">
        <div class="date">{{historyItem.issue?.substring(0,8)+ '-' + historyItem.issue?.substring(8)}}</div>
        <div class="dr">rút ra kết quả</div>
        <div class="ball">
          <em *ngFor="let openNum of historyItem.drawResult?.split(',')">{{openNum}}</em>
        </div>
      </div>
    </div>
  </div>
</div>