import { LatestDrawResult, LotteryGame } from './core/schema';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { DrawResultPaginator } from 'src/app/core/schema';

export interface DrawHistoryByPageInput {
  rowsPerPage?: number;
  code: string;
  page: number;
}

@Injectable({
  providedIn: 'root'
})
export class LotteryService {
  constructor(
    private apollo: Apollo,
  ) { }

  async getLatestDrawResult(wantedCodes: string[]) {
    const resp = await this.apollo
      .query<{ latestDrawResult: LatestDrawResult[] }>({
        query: gql`
        query($wantedCodes: [String!]) {
          latestDrawResult(wantedCodes: $wantedCodes) {
            code
            name
            issue
            drawResult
            drawTime
          }
        }
        `,
        variables: { wantedCodes: wantedCodes },
        fetchPolicy: "network-only",
      })
    return (await (resp?.toPromise())).data || null;
  };

  getDrawHistoryByPage(input: DrawHistoryByPageInput) {
    return this.apollo
      .watchQuery<{ drawHistoryByPage: DrawResultPaginator }>({
        variables: {
          code: input.code,
          rowsPerPage: input.rowsPerPage || 20,
          page: input.page,
        },
        query: gql`
        query($code: String!, $page: Int, $rowsPerPage: Int) {
          drawHistoryByPage(code: $code, page: $page, rowsPerPage: $rowsPerPage) {
            paginatorInfo {
              count
              currentPage
              hasMorePages
              lastItem
              lastPage
              perPage
              total
            }
            data {
              issue
              drawResult
              drawTime
            }
          }
        }
        `,
        fetchPolicy: "network-only",
      })
  }

  async getLotteryGames(wantedCodes: string[]) {
    const resp = await this.apollo
      .query({
        query: gql`
        query($wantedCodes: [String!]) {
          lotteryGames(wantedCodes: $wantedCodes) {
            id
            code
            currentCycle {
              issue
              endTime
              startTimestamp
              endTimestamp
            }
          }
        }        
      `,
        variables: { wantedCodes: wantedCodes },
        fetchPolicy: "network-only",
      });
    return (await (resp?.toPromise())).data as { lotteryGames: LotteryGame[] } || null;
  }
}
