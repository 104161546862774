import { LotteryGame, DrawResultPaginator } from 'src/app/core/schema';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DrawHistoryByPageInput, LotteryService } from 'src/app/lottery.service';
import { Subscription } from 'rxjs';
import { ApolloQueryResult } from '@apollo/client/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  hn1fcLotteryGameInfo: LotteryGame;
  hn5fcLotteryGameInfo: LotteryGame;

  hn1fcDrawHistoryByPage: DrawResultPaginator;
  hn5fcDrawHistoryByPage: DrawResultPaginator;

  lastedHn1fcCycleVal = '';
  lastedHn5fcCycleVal = '';

  hn1fcIntervalId: NodeJS.Timer;
  hn5fcIntervalId: NodeJS.Timer;

  hn1fcIsTimeUp = false;
  hn5fcIsTimeUp = false;

  sub: Subscription[] = [];

  constructor(
    private lotterySer: LotteryService,
  ) { }

  ngOnInit() {
    this.getHn1fcLotteryGameInfo();
    this.getHn5fcLotteryGameInfo();
    this.getHn1fcDrawHistoryByPage();
    this.getHn5fcDrawHistoryByPage();
  }

  ngOnDestroy() {
    clearInterval(this.hn1fcIntervalId);
    clearInterval(this.hn5fcIntervalId);
    this.sub.forEach(s => s.unsubscribe());
  }

  timeUp(e: any) {
    if (e.code === 'hn1fc') {
      this.getHn1fcLotteryGameInfo();
    }
    if (e.code === 'hn5fc') {
      this.getHn5fcLotteryGameInfo();
    }
  }

  async getHn1fcLotteryGameInfo() { // 1分彩每一分鐘開一次獎 要跟5分彩分開打api
    const resp = await this.lotterySer.getLotteryGames(['hn1fc']);
    if (resp && resp?.lotteryGames?.length) {
      this.hn1fcLotteryGameInfo = resp.lotteryGames[0];
    }
  }

  async getHn5fcLotteryGameInfo() {
    const resp = await this.lotterySer.getLotteryGames(['hn5fc']);
    if (resp && resp?.lotteryGames?.length) {
      this.hn5fcLotteryGameInfo = resp.lotteryGames[0];
    }
  }

  getHn1fcDrawHistoryByPage() {
    const queryInput: DrawHistoryByPageInput = {
      code: 'hn1fc',
      rowsPerPage: 4,
      page: 1,
    };
    const sub = this.lotterySer.getDrawHistoryByPage(queryInput).valueChanges.subscribe((result: ApolloQueryResult<any>) => {
      if (!result?.loading && result?.data) {
        this.hn1fcDrawHistoryByPage = result.data.drawHistoryByPage;
        if (!this.lastedHn1fcCycleVal || this.lastedHn1fcCycleVal !== this.hn1fcDrawHistoryByPage.data[0].issue) {
          console.log('update "hn1fc" cycle val');
          this.lastedHn1fcCycleVal = this.hn1fcDrawHistoryByPage.data[0].issue;
          this.hn1fcIsTimeUp = false;
          clearInterval(this.hn1fcIntervalId);
        }
      }
    });
    this.sub.push(sub);
  }

  getHn5fcDrawHistoryByPage() {
    const queryInput: DrawHistoryByPageInput = {
      code: 'hn5fc',
      rowsPerPage: 4,
      page: 1,
    };
    const sub = this.lotterySer.getDrawHistoryByPage(queryInput).valueChanges.subscribe((result: ApolloQueryResult<any>) => {
      if (!result?.loading && result?.data) {
        console.log(result?.data);
        this.hn5fcDrawHistoryByPage = result.data.drawHistoryByPage;
        if (!this.lastedHn5fcCycleVal || this.lastedHn5fcCycleVal !== this.hn5fcDrawHistoryByPage.data[0].issue) {
          console.log('update "hn5fc" cycle val');
          this.lastedHn5fcCycleVal = this.hn5fcDrawHistoryByPage.data[0].issue;
          this.hn5fcIsTimeUp = false;
          clearInterval(this.hn5fcIntervalId);
        }
      }
    });
    this.sub.push(sub);
  }

  transformDate(date: string) {
    return new Date(date);
  }

  timesUp(e: { code: string }) {
    // // 倒數時間到ㄉ時候 每隔 十秒 打一次 api 喔 ～
    if (e?.code === 'hn1fc') {
      if (this.hn1fcIsTimeUp) {
        return;
      }
      this.hn1fcIsTimeUp = true;
      setTimeout(() => { // 重新取得倒數時間
        this.getHn1fcLotteryGameInfo();
      }, 1000);
      this.hn1fcIntervalId = setInterval(() => {
        this.getHn1fcDrawHistoryByPage();
      }, 10000);
    }
    if (e?.code === 'hn5fc') {
      if (this.hn5fcIsTimeUp) {
        return;
      }
      this.hn5fcIsTimeUp = true;
      setTimeout(() => { // 重新取得倒數時間
        this.getHn5fcLotteryGameInfo();
      }, 1000);
      this.hn5fcIntervalId = setInterval(() => {
        this.getHn5fcDrawHistoryByPage();
      }, 10000);
    }
  }
}
